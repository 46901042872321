<template>
    <div class="fluid">
        <div class="fluid" v-for="lang in ui.languages">
            <div class="box-inline-headline first">
                {{ lang.name }} : Görseller
            </div>
            <el-row :gutter="24">
                <el-col :span="6">
                    <el-form-item label="Logo">
                        <fs v-model="form.meta.lang_image[lang.iso_code].logo" />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="Logo Koyu">
                        <fs v-model="form.meta.lang_image[lang.iso_code].logo_dark" />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="Logo Acik">
                        <fs v-model="form.meta.lang_image[lang.iso_code].logo_light" />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="Footer Logo">
                        <fs v-model="form.meta.lang_image[lang.iso_code].logo_footer" />
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'LangImage',
    props : ['form'],
    computed: mapGetters(['ui']),
}
</script>
